<template>
  <div class="background">
    <div id="user-profile">
      <b-navbar class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
        <slot name="navbar">
          <profile-header />
        </slot>
      </b-navbar>

      <b-overlay :show="loading">
        <div class="mb-1" style="margin-top: 50px;">
          <div class="d-flex flex-column align-items-center text-center hero-section">
            <div class="circle-background"></div>
            <p class="hero-text">Mading Artikel</p>
            <p class="hero-main-text">
              Wawasan <span class="text-info">Pendidikan</span>
            </p>
            <p class="hero-main-text">
              <span class="text-info">Artikel</span>, <span class="text-info">Tutorial</span>, dan <span
                class="text-info">Informasi</span> Terbaru.
            </p>
            <p class="hero-subtext">Dapatkan informasi terkini dan bacaan inspiratif melalui blog kami,</p>
            <p class="hero-subtext">serta pelajari tips dan trik pemrograman yang berguna!</p>
            <b-form-group class="blog-search">
              <b-input-group class="input-group-merge">
                <b-form-input id="search-input" v-model="q" placeholder="Cari Artikel" style="width: 100px;"/>
                  <b-input-group-append class="cursor-pointer" is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-append>
              </b-input-group>
            </b-form-group>

          </div>
        </div>

        <div class="container content-section">
          <section id="card-images">
            <div class="text-center mt-5 mb-5" v-if="dataArtikel.length < 1">
              <b-img :src="require('@/assets/images/elp/empty.png')" fluid width="150px" />
              <h5 class="text-primary">Mading tidak ditemukan!!</h5>
            </div>

            <b-row class="blog-list-wrapper" v-else>
              <b-col v-for="(item, i) in currentPageItems" :key="i" cols="12" md="6" lg="4">
                <div class="article-wrapper mb-4 shadow-lg rounded-lg">
                  <b-link :to="{ name: 'artikel-detail', params: { slug: item.slug } }">
                    <b-img
                      src="https://cdn.britannica.com/85/13085-050-C2E88389/Corpus-Christi-College-University-of-Cambridge-England.jpg"
                      class="article-image img-fluid" alt="Artikel image" />
                  </b-link>

                  <div class="text-left mt-1 px-3">
                    <b-badge :variant="item.category.color" v-if="item.category">
                      {{ item.category.name }}
                    </b-badge>

                    <b-link :to="{ name: 'artikel-detail', params: { slug: item.slug } }">
                      <h4 class="article-title mt-1 text-dark" style="font-size: 1rem;">
                        {{item.title}}
                      </h4>
                    </b-link>

                    <p class="text-muted mt-1 mb-3" style="font-size: 1rem;">
                      {{ new Date(item.created_at).toLocaleDateString() }}</p>
                  </div>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="my-4">
                  <b-pagination v-model="filters.page" align="center" :total-rows="rows" :per-page="filters.perPage"
                    first-number last-number>
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-col>
            </b-row>
          </section>
        </div>
      </b-overlay>

      <footer-home />
    </div>
  </div>
</template>



<script>
  import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BCardBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    VBTooltip,
    BCardGroup,
    BOverlay,
    //   BNavbar,
    //   BContainer,
  } from "bootstrap-vue";
  import {
    kFormatter
  } from "@core/utils/filter";
  import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
  import ProfileHeader from "./components/Navbar.vue";
  import FooterHome from "./components/Footer.vue";
  import Ripple from "vue-ripple-directive";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BFormInput,
      BCardText,
      BCardBody,
      BCardTitle,
      BCardGroup,
      BOverlay,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BLink,
      BBadge,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BImg,
      BPagination,
      ContentWithSidebar,
      // BNavbar,
      // BContainer,
      ProfileHeader,
      FooterHome,
    },
    data() {
      return {
        search_query: "",
        blogList: [],
        blogSidebar: {},
        currentPage: 1,
        perPage: 1,
        rows: 1,
        q: null,
        filters: {
          page: 1,
          perPage: 4,
        },
        paginated_items: {},
        currentPageIndex: 0,
        nbPages: 0,
        dataArtikel: [],
        loading: false,
      };
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    watch: {
      q: {
        handler(value) {
          if (this.q == "") {
            this.q = null;
          } else {
            this.getData();
          }
        },
        deep: true,
      },
    },
    created() {
      this.getData();
    },
    computed: {
      currentPageItems() {
        let lengthAll = this.dataArtikel.length;
        this.nbPages = 0;
        for (let i = 0; i < lengthAll; i = i + this.filters.perPage) {
          this.paginated_items[this.nbPages] = this.dataArtikel.slice(
            i,
            i + this.filters.perPage
          );
          this.nbPages++;
        }

        return this.paginated_items[this.filters.page - 1];
      },
    },
    methods: {
      kFormatter,
      tagsColor(tag) {
        if (tag === "Quote") return "light-info";
        if (tag === "Gaming") return "light-danger";
        if (tag === "Fashion") return "light-primary";
        if (tag === "Video") return "light-warning";
        if (tag === "Food") return "light-success";
        return "light-primary";
      },
      convertShortText(htmlString, numLimit) {
        const clearString = this.removeTags(htmlString);
        return this.truncateString(clearString, numLimit);
      },
      getData() {
        this.loading = true;
        let params = {
          search: this.q,
          type: "article",
        };
        this.$store
          .dispatch("artikel/publicIndex", params)
          .then((res) => {
            const artikel = res.data.data;
            const result = artikel.map((item) => ({
              ...item,
              text: item.content ? this.convertShortText(item.content, 70) : "",
            }));
            this.loading = false;
            this.dataArtikel = result;
            this.rows = result.length;
          })
          .catch((err) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error `,
                icon: "XIcon",
                variant: "danger",
                text: err.message,
              },
            });
          });
      },
      getFormattedTitle(title) {
        var count = 40;
        var result = title.slice(0, count) + (title.length > count ? "..." : "");
        return result;
      },
    },
  };
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

  * {
    font-family: "Open Sans", sans-serif;
  }

  .hero-section {
    margin-top: 2rem;
    position: relative;
    padding: 2rem;
    background: linear-gradient(to bottom, #cce8ed, #ffffff);
    height: auto;
    width: 100%;
  }

  .hero-text {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .hero-main-text {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .hero-subtext {
    font-size: 1rem;
    font-weight: bold;
  }

  .circle-background {
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .blog-search {
    max-width: 75%;
    width: 100%;
    margin-top: 20px;
  }

  .article-wrapper {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 12px;
    overflow: hidden;
    background-color: #f8f9fa;
    border: 1px solid #e0e0e0;
  }

  .article-wrapper:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  }

  .article-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  .article-wrapper:hover .article-image {
    transform: scale(1.05);
  }

  .article-title {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.5rem;
    color: #343a40;
  }

  .text-left {
    text-align: left;
  }

  .content-section {
    padding: 0 5vw;
    margin-top: 2rem;
  }

  @media (max-width: 768px) {
    .hero-main-text {
      font-size: 1.6rem;
    }

    .article-image {
      height: 180px;
    }

    .circle-background {
      width: 80px;
      height: 80px;
    }
  }
</style>